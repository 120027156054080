
const validateEmail = (curstring) => {
	return validateString(curstring, /^[\w\-.+]+@[a-zA-Z0-9.-]+\.[a-zA-z0-9]{2,4}$/);
}

const validateSMSNumber = (curstring) => {
	// SMS Destination +639xxNNNNNNN | 639xxNNNNNNN | 09xxNNNNNNN
	// 7-9, in case expands to 07xx
	//return validateString(curstring, /^(^\+63|^63|^0)[7-9]\d{9}$/);
	return validateString(curstring, /^(^0)[7-9]\d{9}$/);
}

const validateMobile = (curstring) => {
	// (^\+[0-9]{1}|^251|^0)?(9|7)\d{8}$
	return validateString(curstring, /^[+]?[().\s0-9,;-]*$/);
}

const validateString = (curstring, regexstr) => {
	if(curstring.match(regexstr)){
		return true;
	}
	return false;
}

const filterNumeric = (num) => {
	if (num === null)
		return "";
	return (""+num).replace(/[^0-9.\-+]/g, '');
}

const filterInteger = (num) => {
	if (num === null)
		return "";
	return (""+num).replace(/[^0-9\-+]/g, '');
}


const filterSMSNumber = (str) => {
	var prefix = "";
	str = str+"";
	if (str.charAt(0) === "+") {
		// Allow only 09xxNNNNNNN form for simplicity
		//prefix = str.charAt(0);
		str = str.substring(1);
	}
	return prefix+str.replace(/[^0-9]/g, '');
}

const integer = (num) => {
	return parseInt(numeric(num), 10)
}

const currency = (num) => {
	return numeric(num).toFixed(2)
}

const numeric = (num) => {
	var tmpnum = filterNumeric(num);
	if (tmpnum.length < 1)
		return 0;
	if (isNaN(parseFloat(tmpnum))) {
		return 0;
	}
	return parseFloat(tmpnum);
}

const numericstr = (num, numdecimal) => {
	var tmpdecimal = 0;
	if (!numdecimal) {
		tmpdecimal = 0;
	} else {
		tmpdecimal = numdecimal;
	}
	if (tmpdecimal <= 3) {
		// Adds comma to decimal values when above 3
		//https://stackoverflow.com/questions/55556221/how-do-you-format-a-number-to-currency-when-using-react-native-expo
		return numeric(num).toFixed(tmpdecimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}
	// if numdecimal is
	const wholenum = numericstr(Math.floor(numeric(num)), 0);
	const decimalstr = numeric(num).toFixed(numdecimal);
	return wholenum+"."+decimalstr.substring(decimalstr.length - numdecimal, decimalstr.length);
}

const integerstr = (num) => {
	return numericstr(num, 0);
}
const currencystr = (num) => {
	return numericstr(num, 2);
}

const datestr = (str, mode="") => {
	if (str === null) {
		return "";
	}
	if (str.length < 10) {
		return "";
	}
	if (mode !== "") {
		return str.substring(0, 10);
	}
	return str.substring(5,7)+"/"+str.substring(8,10)+"/"+str.substring(0,4);;
}


const timestr = (str, mode = "") => {
	if (str === null) {
		return "";
	}
	var baseidx = 11;
	// YYYY-mm-dd HH:ii:ss  or   HH:ii:ss
	if (str.length < 16) {
		baseidx = 0;
		if (str.indexOf(":") < 1) {
			return ""
		}
	}
	if (str.length < baseidx + 5) {
		return "";
	} else if (str.length < baseidx + 8) {
		str = str + ":00";
	}
	const hrval = parseInt(str.substring(baseidx,baseidx+2), 10);
	if (isNaN(hrval)) {
		return "";
	}
	if (mode !== "") {
		return str.substring(baseidx);
	}
	const minsecstr = str.substring(baseidx+2, baseidx+8);
	var ampmstr = "am";
	var hrstr = hrval;
	if (hrval === 12) {
		if (minsecstr === ":00:00") {
			ampmstr = "nn";
		} else {
			ampmstr = "pm";
		}
	} else if (hrval > 12) {
		ampmstr = "pm";
		hrstr = ""+(hrval - 12);
	} else if (hrval === 0) {
		hrstr = "12";
		if (minsecstr === ":00:00") {
			ampmstr = "mn";
		}
	}
	return hrstr+minsecstr+ampmstr;
}


const datetimestr = (str, mode="") => {
	return trimstr(datestr(str, mode)+" "+timestr(str, mode));
}


const getDateStr = (tzoffsethr) => {
	var utcdate = new Date();
	var tzoffsetfactor = 3600000;
	var tzoffsetms = 0;
	if(typeof tzoffsethr === "undefined") {
		tzoffsetms = 8*tzoffsetfactor; // GMT+8; Asia/Manila
	} else {
		tzoffsetms = tzoffsethr*tzoffsetfactor;
	}
	var localdate = new Date(utcdate.getTime()+tzoffsetms);
	var tmpstr = localdate.toISOString();
	return tmpstr.replace("T"," ").substring(0,19);
}

const trimstr = (str) => {
	if (str === null) {
		return "";
	}
	return (str+"").replace(/^\s+|\s+$/g,'');
}

const utf8str = (str) => {
	// String data should be utf8 already
	return str+"";
	//return Buffer.from(str, 'utf-8').toString();
	//return Buffer.from(loadstr(str,""), 'latin1').toString();
}

const defaultstr = (object, key, defaultvalue="") => {
	if (object.hasOwnProperty(key)) {
		return loadstr(object[key], defaultvalue);
	}
	return defaultvalue;
}


function loadstr(strobj, defaultvalue="") {
	if (strobj !== null) {
		if (strobj.hasOwnProperty("type") && strobj.hasOwnProperty("data")) {
			if (strobj.type === "Buffer") {
				var tmpidx = 0;
				var output = "";
				while (tmpidx < strobj.data.length) {
					output = output + String.fromCharCode(strobj.data[tmpidx]);
					tmpidx++;
				}
				return output;
			}
		}
		// Ensure string return value
		if (strobj+"" !== "") {
			return strobj+"";
		}
	}
	return defaultvalue;
}

module.exports = {
	validateSMSNumber,
	getDateStr,
	validateEmail,
	validateMobile,
	filterInteger,
	filterNumeric,
	filterSMSNumber,
	defaultstr,
	utf8str,
	trimstr,
	datestr,
	timestr,
	datetimestr,
	numericstr,
	currencystr,
	integerstr,
	numeric,
	currency,
	integer
}

