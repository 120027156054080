
import React, {useState, useEffect} from "react"

import Layout from "../../components/layout"

import * as tablescroll from "../../styles/module/tablescroll.module.css"
import * as styles from "../../components/webapps/style.module.css"

const requestJSON = require("../../../lib/requestJSON");
const webappsAPI = require("../../../lib/requestWebapps");
const formatTools = require("../../../lib/formatTools");
const FULLCOLSPANVALUE=1000;
const MAXLOCATIONLISTLEN=2;

const DeliveryRatePage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	const [locationlist, setLocationlist] = useState([]);
	const [listdata, setListdata] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getLocationlist("");
	}, []);

	function getLocationlist(locationcode)
	{
		setLoading(true);
		const baseurl = "https://geocode-ph.ngnw.ph/";
		var url = "province.json";
		if (locationcode.length === 9) {
			if (locationcode !== "000000000") {
				if (locationcode.substring(4) === "00000") {
					url = "citylist/"+locationcode+".json";
				} else {
					url = "barangaylist/"+locationcode+".json";
				}
			} else {
				locationcode = "";
			}

		}
		requestJSON.send( baseurl+ url, "GET", {}).then(locresponse => {
			if (locationcode.length < 1) {
				setListdata(locresponse);
				setLoading(false);
				return;
			} else if (locationcode.substring(4,6) === "00") {
				setListdata(locresponse);
				setLoading(false);
				return;
			}
			webappsAPI.queryData("NNPICKUPFEE", {
					fieldlist: ["nnpickupfee_locationcode", "nnpickupfee_fee", "nnpickupfee_discount", "nnpickupfee_cutoffamount"],
					// Hardcoded to Onprem Logistics
					condlist: ["nnpickup_id=6", "nnpickupfee_locationcode like '"+locationcode.substring(0,6)+"%'"],
					getallrows: true
				}, token).then(response => {
					if (response.status === "OK") {
						if (response.records.length === 1) {
							setListdata([{
								"code":"","name":"All Barangays",
								"fee":response.records[0].nnpickupfee_fee,
								"discount":response.records[0].nnpickupfee_discount,
								"target":response.records[0].nnpickupfee_cutoffamount
							}]);
							setLoading(false);
							return;
						} else if (response.records.length > 1) {
							// Populate each entry
							var foundflag = false;
							var recordidx = 0;
							var locresponseidx = 0;
							while (locresponseidx < locresponse.length) {
								foundflag = false;
								recordidx = 0;
								while (recordidx < response.records.length) {
									if (response.records[recordidx].nnpickupfee_locationcode === locresponse[locresponseidx].code) {
										locresponse[locresponseidx].fee = response.records[recordidx].nnpickupfee_fee;
										locresponse[locresponseidx].discount = response.records[recordidx].nnpickupfee_discount;
										locresponse[locresponseidx].target = response.records[recordidx].nnpickupfee_cutoffamount;
										foundflag = true;
										break;
									}
									recordidx++;
								}
								if (foundflag === false) {
									locresponse[locresponseidx].fee = -1;
									locresponse[locresponseidx].discount = -1;
									locresponse[locresponseidx].target = -1;

								}
								locresponseidx++;
							}

							setListdata(locresponse);
							setLoading(false);
							return;
						}
					}
					setListdata([{
						"code":"","name":"Not Covered",
						"fee":-1,
						"discount":-1,
						"target":-1
					}]);
					setLoading(false);
				});
		});
	}
/*

		$outarray = array();
		if (!empty($_REQUEST['locationcode'])) {
			$locationcode = substr(str_replace("'", "", $_REQUEST['locationcode']), 0, 6);
			$tmpquerycond = " where nnpickup_id=6";
			$tmpquerycond .= " and nnpickupfee_locationcode like '$locationcode%'";

			$tmpres = db_query("select * from NNPICKUPFEE$tmpquerycond", $connection_id);
			while ($tmprow = db_getnextdata($tmpres)) {
				array_push($outarray, array(
										"code"=>$tmprow['nnpickupfee_locationcode'],
										"fee"=>$tmprow['nnpickupfee_fee'],
										"discount"=>$tmprow['nnpickupfee_discount'],
										"target"=>$tmprow['nnpickupfee_cutoffamount'],
								));
			}
		}

		echo util_jsonencode($outarray);//format the array into json data

		db_disconnect($connection_id);

*/

	function defaultSearchRowClick(e, rowdata)
	{
		if (locationlist.length < MAXLOCATIONLISTLEN) {
			var tmplocationlist = [...locationlist];

			tmplocationlist.push(rowdata)
			setLocationlist(tmplocationlist);
			getLocationlist(rowdata.code);

		}
	}


	function updateFilter(e, idx, newcode)
	{
		if (e) {
			e.preventDefault();
		}
		var tmplist = [];
		if (newcode !== "") {
			var tmplistidx = 0;
			while(true) {
				tmplist.push(locationlist[tmplistidx]);
				if (locationlist[tmplistidx].code === newcode) {
					break;
				}
				tmplistidx++;
			}
		}
		setLocationlist(tmplist);
		getLocationlist(newcode);
	}


	return <Layout fullPath={location.pathname}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
				<div className={styles.searchheadercontrol+" text-centered"}>
					<h2 className={styles.searchtabletitle}>
						{pagetitle}
					</h2>
					<div>
						{locationlist.length === 0?<>Please Choose Province</>:<button
							className={"textbutton textbutton-medium"}
							disabled={loading}
							onClick={(e)=>{updateFilter(e, 0, "")}}
							title={locationlist[0].name}
						>
							{locationlist[0].name+" ▾"}
						</button>}
						{locationlist.map((listentry, idx)=> {
							if (idx === 0) {
								return <></>
							}
							return <>
								&nbsp;&gt;&nbsp;
								<button
									className={"textbutton textbutton-medium"}
									disabled={loading}
									onClick={(e)=>{updateFilter(e, idx, locationlist[idx-1].code)}}
									title={listentry.name}
								>
									{listentry.name+" ▾"}
								</button>
							</>
						})}
					</div>
				</div>
				<div className={tablescroll.headerfooter + " "+styles.searchtableholder}>
					<table className={styles.searchtable}>
						{loading?
							<tbody>
								<tr>
									<td colSpan={FULLCOLSPANVALUE} style={{borderBottom:"none"}}>
										<div className="text-centered font-size-medium">
											Loading...
										</div>
									</td>
								</tr>
							</tbody>
						:<>
							<thead>
								<tr className={styles.searchtableheader}>
									<td>#</td>
									{listdata.length > 0 ? <>
										{listdata[0].hasOwnProperty("fee") ? <>
											<td>Name</td>
											<td>Delivery Fee</td>
											<td>Amount to get 50% Discount</td>
										</>:
										<td colSpan={2}>Name</td>}
									</>:
									<td colSpan={2}>Name</td>}
								</tr>
							</thead>
							<tbody>{listdata.length < 1?
								<tr className={styles.searchtablerow} key={"listnotfound"}>
									<td colSpan={FULLCOLSPANVALUE} className={"text-centered font-size-medium"}>No Records Found</td>
								</tr>
							:<>
								{listdata.map((entry, idx)=> {
									return <tr className={locationlist.length < MAXLOCATIONLISTLEN?styles.searchtablerowclickable+" "+styles.searchtablerow:styles.searchtablerow} key={"list"+idx} onClick={(e)=>{e.preventDefault(); defaultSearchRowClick(e,entry)}}>
										<td className={styles.searchtablecellright+" "+styles.searchtablerownum}>
											{idx+1}
										</td>
										<td>
											{entry.name}
										</td>
										{entry.hasOwnProperty("fee") ? <>
											<td className={styles.searchtablecellright}>
												{entry.fee>0?formatTools.currencystr(entry.fee):"N/A"}
											</td>
											<td  className={styles.searchtablecellright}>
												{entry.fee>0?formatTools.currencystr(entry.target):"N/A"}
											</td>
										</>:<td className={styles.searchtablecellright}>&gt;</td>}
									</tr>
								})}
							</>}</tbody>
						</>}
					</table>
				</div>
				<div className="font-size-medium">&nbsp;</div>

		</Layout>
}

export default DeliveryRatePage;
